import { FC } from 'react';

import { ProgramRegistrationQuestion } from '@guider-global/shared-types';

import { FormTextFieldTyped, FormAutocomplete } from 'components';
import { BaseReactHookFormComponentProps } from '../../pages/Programs/ProgramManageGuidePage';

export interface ProgramManageGuideFieldProps
  extends BaseReactHookFormComponentProps {
  question: ProgramRegistrationQuestion;
}

const ProgramManageGuideField: FC<ProgramManageGuideFieldProps> = ({
  control,
  errors,
  question,
}) => {
  const questionId = question.id.current;
  const questionType = question.type;
  const questionLabel = question?.text?.input_label ?? '';
  const isRequired = question.required;

  if (questionType === 'free-text') {
    const freeTextConfig = question?.free_text_config;
    const rows = freeTextConfig?.text_area_size === 'multiline' ? 4 : 1;

    return (
      <FormTextFieldTyped
        name={questionId}
        control={control}
        label={questionLabel}
        errors={errors}
        rows={rows}
        required={isRequired}
      />
    );
  }

  if (questionType === 'select') {
    const questionOptions =
      question?.options?.map((option) => ({
        label: option.label ?? '',
        value: option.id.current ?? '',
      })) ?? [];

    return (
      <FormAutocomplete
        defaultValue={''}
        name={questionId}
        control={control}
        label={questionLabel}
        errors={errors}
        options={questionOptions}
        rules={{ required: isRequired }}
      />
    );
  }

  if (questionType === 'multi-select') {
    const questionOptions =
      question?.options?.map((option) => ({
        label: option.label ?? '',
        value: option.id.current ?? '',
      })) ?? [];

    const maximum_selections =
      question?.multi_select_config?.maximum_selections ?? 5;
    const minimum_selections =
      question?.multi_select_config?.minimum_selections ?? 0;
    const errorText = question?.multi_select_config?.text;

    const minimumSelectionsNotMetValidationError =
      errorText?.minimum_selections_not_met_validation_error;
    const maximumSelectionsExceededValidationError =
      errorText?.maximum_selections_exceeded_validation_error;
    // Events
    const multiSelectValidate = (value: unknown) => {
      if (question.type === 'multi-select') {
        const valueLength = (value as string[]).length;
        if (valueLength > maximum_selections)
          return maximumSelectionsExceededValidationError;
        if (valueLength < minimum_selections)
          return minimumSelectionsNotMetValidationError;
        return true;
      }
    };

    return (
      <FormAutocomplete
        multiple
        defaultValue={[]}
        name={questionId}
        control={control}
        label={questionLabel}
        errors={errors}
        options={questionOptions}
        rules={{ required: isRequired, validate: multiSelectValidate }}
      />
    );
  }

  return <></>;
};

export default ProgramManageGuideField;
