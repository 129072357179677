import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import ProgramManageGuideField from '../ProgramManageGuideField';

import { Asterisk } from 'components';

import {
  BaseReactHookFormComponentProps,
  ProgramManageGuidePageRole,
} from 'pages/Programs/ProgramManageGuidePage';
import { Program } from '@guider-global/shared-types';

export interface ProgramManageGuideFieldsProps
  extends BaseReactHookFormComponentProps {
  role: ProgramManageGuidePageRole;
  program: Program;
  isSkillsEnabled: boolean;
  isGroupProgram: boolean;
}

const ProgramManageGuideFields: FC<ProgramManageGuideFieldsProps> = ({
  role,
  program,
  isSkillsEnabled,
  control,
  errors,
  isGroupProgram,
}) => {
  const questionIndexOffset = isGroupProgram ? 2 : 0; // guide has 2 extra required questions

  const registrationQuestions =
    program?.registration?.registration_questions?.filter(
      (registrationQuestion) =>
        [role, 'both'].includes(registrationQuestion.audience),
    );

  return (
    <Box
      sx={{
        mt: isSkillsEnabled ? 6 : 0,
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: 6,
      }}
    >
      {registrationQuestions?.map((question, index) => {
        const questionIndex =
          index + (isSkillsEnabled ? 3 : 2) + questionIndexOffset;
        const isRequired = question.required;

        return (
          <Box key={`program-registration-question-${index}`}>
            <Typography variant="h6">
              {`${questionIndex}. `}
              {question[role]?.question_title}
              {isRequired && <Asterisk />}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ whiteSpace: 'pre-line' }}
              color="text.secondary"
            >
              {question[role]?.question_description}
            </Typography>
            <ProgramManageGuideField
              control={control}
              errors={errors}
              question={question}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ProgramManageGuideFields;
