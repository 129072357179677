import { Typography } from '@mui/material';
import { FC } from 'react';
import { Control, FieldErrors } from 'react-hook-form';

import { ProgramRegistrationSkills } from '@guider-global/shared-types';

import { Asterisk, FormAutocomplete } from 'components';
import { ProgramManageGuidePageRole } from 'pages/Programs/ProgramManageGuidePage';

export interface ProgramManageGuideSkillsProps {
  control: Control;
  errors: FieldErrors;
  skillsConfig: ProgramRegistrationSkills | undefined;
  role: ProgramManageGuidePageRole;
  isGroupProgram: boolean;
}

const ProgramManageGuideSkills: FC<ProgramManageGuideSkillsProps> = ({
  control,
  errors,
  skillsConfig,
  role,
  isGroupProgram,
}) => {
  if (!skillsConfig?.enable_skills_question) {
    return <></>;
  }

  const questionIndexOffset = isGroupProgram ? 2 : 0; // guide has 2 extra required questions
  const questionIndex = 1 + questionIndexOffset;

  const skillsOptions =
    skillsConfig?.options?.map((skill) => ({
      label: skill.label ?? '',
      value: skill.id.current ?? '',
    })) ?? [];

  const maximumSelections =
    skillsConfig?.multi_select_config?.maximum_selections ?? 5;
  const minimumSelections =
    skillsConfig?.multi_select_config?.minimum_selections ?? 0;
  const errorText = skillsConfig?.multi_select_config?.text;

  const minimumSelectionsNotMetValidationError =
    errorText?.minimum_selections_not_met_validation_error;
  const maximumSelectionsExceededValidationError =
    errorText?.maximum_selections_exceeded_validation_error;

  // Events
  const multiSelectValidate = (value: unknown) => {
    const valueLength = (value as string[]).length;
    if (valueLength > maximumSelections)
      return maximumSelectionsExceededValidationError;
    if (valueLength < minimumSelections)
      return minimumSelectionsNotMetValidationError;
    return true;
  };

  return (
    <>
      <Typography variant="h6">
        {`${questionIndex}. `}
        {skillsConfig?.[role].question_title}
        <Asterisk />
      </Typography>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{ whiteSpace: 'pre-line' }}
      >
        {skillsConfig?.[role].question_description}
      </Typography>

      <FormAutocomplete
        multiple
        defaultValue={[]}
        name={'skills'}
        control={control}
        label={skillsConfig?.text?.input_label ?? ''}
        errors={errors}
        options={skillsOptions}
        rules={{
          required: skillsConfig?.required,
          validate: multiSelectValidate,
        }}
        data-cy="pages_GuideRegistrationAboutYouPage_skills-field"
      />
    </>
  );
};

export default ProgramManageGuideSkills;
